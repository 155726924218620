import React, { useEffect, useState } from "react";
import {
  HStack,
  Box,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  Text,
} from "@chakra-ui/react";
import "remixicon/fonts/remixicon.css";

const NavBar = (props) => {
  const [user, setUser] = useState(window.sessionStorage.getItem("user"));
  const handleAboutClick = async (e) => {
    e.preventDefault();
    props.seeProfile(false);
    props.seeSignIn(false);
    props.seeSignUp(false);
    props.seeLeaderboard(false);
    window.sessionStorage.getItem("user")
      ? await props.seeReturning(true)
      : await props.seeHome(true);
    document
      .getElementById("about-text")
      .scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const handleHomeClick = async (e) => {
    e.preventDefault();
    props.seeProfile(false);
    props.seeSignIn(false);
    props.seeSignUp(false);
    props.seeLeaderboard(false);
    window.sessionStorage.getItem("user")
      ? await props.seeReturning(true)
      : await props.seeHome(true);
    document.body.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const seeSignIn = (e) => {
    e.preventDefault();
    props.seeProfile(false);
    props.seeReturning(false);
    props.seeHome(false);
    props.seeSignUp(false);
    props.seeLeaderboard(false);
    props.seeSignIn(true);
  };

  const seeLeaderboard = (e) => {
    e.preventDefault();
    props.seeProfile(false);
    props.seeReturning(false);
    props.seeHome(false);
    props.seeSignIn(false);
    props.seeSignUp(false);
    props.seeLeaderboard(true);
  };

  const seeProfile = (e) => {
    e.preventDefault();
    props.seeReturning(false);
    props.seeHome(false);
    props.seeSignIn(false);
    props.seeSignUp(false);
    props.seeLeaderboard(false);
    props.seeProfile(true);
  };

  const signOut = () => {
    window.sessionStorage.removeItem("user");
    window.sessionStorage.removeItem("keybind");
    window.location.reload();
  };

  useEffect(() => {
    setUser(window.sessionStorage.getItem("user"));
  }, [props.reload, props.user]);
  return (
    <>
      <Box
        display={"flex"}
        shadow="sm"
        position="fixed"
        w="100vw"
        bg="white"
        zIndex={2}
        border="1px"
        borderColor="gray.200"
      >
        <Box
          p={3}
          fontSize="4xl"
          textColor="#30ACFB"
          onClick={handleHomeClick}
          _hover={{ bg: "#C0E6FD", cursor: "pointer" }}
        >
          RVIS
        </Box>
        <HStack spacing="10" p="3" mr="auto" id="navbar-desktop">
          <Button
            onClick={handleAboutClick}
            variant="unstyled"
            fontSize="2xl"
            fontWeight="normal"
            _hover={{ bg: "#C0E6FD" }}
          >
            About
          </Button>
          <Button
            variant="unstyled"
            fontSize="2xl"
            fontWeight="normal"
            onClick={seeLeaderboard}
            _hover={{ bg: "#C0E6FD" }}
          >
            Leaderboard
          </Button>
          <Button
            as="a"
            href="https://forms.gle/NXNjKkTSuNjwjUva9"
            target="_blank"
            rel="noopener noreferrer"
            fontSize="2xl"
            fontWeight="normal"
            variant="unstyled"
            pt={1}
            _hover={{ bg: "#C0E6FD" }}
          >
            Contact Us
          </Button>
        </HStack>
        {window.sessionStorage.getItem("user") ? (
          <HStack spacing="10" px="5">
            <Menu>
              <MenuButton display="flex" mx={5} ml="auto">
                <Avatar name={user} cursor="pointer" />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={seeProfile}>
                  <i className="ri-user-line" />
                  <Text ml={3}>View Profile</Text>
                </MenuItem>
                <MenuItem onClick={signOut}>
                  <i className="ri-logout-box-line" />
                  <Text ml={3}>Sign Out</Text>
                </MenuItem>
              </MenuList>
            </Menu>
            {/* <Box fontSize="3xl">Sign In</Box> */}
          </HStack>
        ) : (
          <HStack spacing="10" p="5" id="signin-desktop">
            <Button
              variant="outline"
              fontSize="2xl"
              fontWeight="normal"
              onClick={seeSignIn}
              border="2px"
              color="#30acfb"
            >
              Sign In
            </Button>
          </HStack>
        )}
      </Box>
    </>
  );
};

export default NavBar;
