import React, { useState, useEffect } from "react";
import { useToast, Text, Box, Input } from "@chakra-ui/react";

const SignIn = (props) => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const toast = useToast({ position: "top" });
  const backend = process.env.REACT_APP_IP;

  const transition1 = () => {
    sessionStorage.setItem("user", username);
    props.user(username);
    props.hideself(false);
    props.seeNav(true);
    props.transition1(true);
  };

  const transition2 = () => {
    props.hideself(false);
    props.transition2(true);
  };

  const checkUser = async () => {
    if (username && password) {
      let body = JSON.stringify({
        username: username,
        password: password,
      });
      const response = await fetch(backend + "signin", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      });
      const result = await response.json();
      if (result === "user does not exist" || result === "password incorrect") {
        toast({
          title: "Username or password incorrect",
          description:
            "Please check spelling or sign up if you have not already",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else if (result) {
        if (result.keybind) {
          window.sessionStorage.setItem("keybind", result.keybind);
          // console.log("signnin/storage: ", result.keybind);
          toast({
            title: "Welcome back",
            description: "You are now logged in.",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          // localStorage.setItem("user", username)
          transition1();
        } else {
          toast({
            title: "Error",
            description:
              "There has been an error logging in. Please try again later.",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      } else {
        toast({
          title: "Error",
          description:
            "There has been an error logging in. Please try again later.",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <div className="signUp">
      <h1 id="title">Sign in to play</h1>
      <Box display={"flex"} justifyContent={"center"}>
        <Text color="whiteAlpha.600" mr={1}>
          New user? Sign up
        </Text>{" "}
        <button
          className="rtrn-btn"
          onClick={() => {
            transition2();
          }}
        >
          here
        </button>
      </Box>

      <form
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            checkUser();
          }
        }}
      >
        <label className="label">Username</label>
        <Input
          className="input"
          bg="white"
          type="text"
          autoComplete="username"
          onChange={(e) => setUsername(e.target.value)}
        />

        <label className="label">Password</label>
        <Input
          className="input"
          bg="white"
          type="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
        />
        <br></br>
        <br></br>
        <Box className="continue-btn-wrapper">
          {/* <button
            onClick={() => {
              checkUser();
            }}
            className="back-btn"
          >
            Back
          </button> */}
          <button
            onClick={() => {
              checkUser();
            }}
            className="submit-btn"
            type="button"
          >
            Submit
          </button>
        </Box>
      </form>
    </div>
  );
};

export default SignIn;
