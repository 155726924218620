import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Kbd } from "@chakra-ui/react";

const PracticeDir = (props) => {
  const [done, setDone] = useState(false);
  let f, j;
  if (props.keybind < 0.5) {
    f = props.choices[0];
    j = props.choices[1];
  } else {
    j = props.choices[0];
    f = props.choices[1];
  }

  // const transition1 = useCallback(
  //   (e) => {
  //     if (done) {
  //       props.hideself(false);
  //       props.transition();
  //     } else {
  //       setDone(true);
  //     }
  //   },
  //   [props, done]
  // );

  const transition1 = useCallback(
    (e) => {
      props.hideself(false);
      props.transition();
    },
    [props]
  );

  useEffect(() => {
    document.addEventListener("keydown", transition1, false);

    return () => {
      document.removeEventListener("keydown", transition1, false); // to prevent mem leaks
    };
  }, [transition1]);

  return (
    <form>
      <h1 className="intro-header-text">Practice Session</h1>
      <label>
        The practice session will consist of two blocks with ten trials each.{" "}
      </label>
      <br></br>
      <label>You will be briefly shown an image.</label>
      <br />
      <label>
        Your task is to describe the image to the best of your ability.
      </label>
      {/* <label>Place your left hand index finger on the 'f' key.</label>
          <br></br>
          <label>Place your right hand index finger on the 'j' key.</label> */}
      <br></br>
      <br />
      <label>
        <strong>Press any key to continue</strong>
      </label>
      <br />
      <br />
    </form>
    // <>
    //   {done && (
    //     <form>
    //       <h1 className="intro-header-text">Practice Session</h1>
    //       <label>
    //         You will receive feedback on your answer :{" "}
    //         <span id="correct-small">correct</span> or{" "}
    //         <span id="wrong-small">wrong</span>.
    //       </label>
    //       <br></br>
    //       <label>
    //         If you fail to respond within 5 seconds your answer will count as{" "}
    //         <span id="wrong-small">wrong</span>.
    //         <br />
    //       </label>
    //       <label>
    //         You will be briefly shown an image. <br />
    //         Your task to identify if the image contains an animal or non-animal.{" "}
    //         <br />
    //         There will be an equal number of each.
    //       </label>
    //       <br></br>
    //       <label>
    //         Press <strong>f</strong> on your keyboard for {f} or{" "}
    //         <strong>j</strong> for {j}.
    //       </label>
    //       <br></br>
    //       <div>
    //         <label>{f}</label>
    //         <Kbd mx="4" fontSize="3xl">
    //           f
    //         </Kbd>
    //         <Kbd mx="4" fontSize="3xl">
    //           j
    //         </Kbd>
    //         <label>{j}</label>
    //       </div>
    //       <br />

    //       {/* <input
    //       type="submit"
    //       onClick={() => {
    //         transition1();
    //       }}
    //       value="BEGIN"
    //       className="accept-btn"
    //     /> */}
    //       {/* <input
    //       type="submit"
    //       onClick={() => {
    //         transition1();
    //       }}
    //       value="BEGIN"
    //       className="accept-btn"
    //     /> */}
    //       <label>
    //         <strong>Press any key to continue</strong>
    //       </label>
    //       <br />
    //       <br />
    //     </form>
    //   )}
    //   {!done && (
    //     <form>
    //       <h1 className="intro-header-text">Practice Session</h1>
    //       <label>
    //         The practice session will consist of two blocks with ten trials
    //         each.{" "}
    //       </label>
    //       <br></br>
    //       <label>You will be briefly shown an image.</label>
    //       <br />
    //       <label>
    //         Your task is to describe the image to the best of your ability.
    //       </label>
    //       {/* <label>Place your left hand index finger on the 'f' key.</label>
    //       <br></br>
    //       <label>Place your right hand index finger on the 'j' key.</label> */}
    //       <br></br>
    //       <br />
    //       <label>
    //         <strong>Press any key to continue</strong>
    //       </label>
    //       <br />
    //       <br />
    //     </form>
    //   )}
    // </>
  );
};

export default PracticeDir;
