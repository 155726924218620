import React from "react";
import NavBar from "./navbar";
import { Text, Box } from "@chakra-ui/react";
import "remixicon/fonts/remixicon.css";

const Mobile = () => {
  return (
    <>
      <Box
        display={"flex"}
        shadow="sm"
        position="fixed"
        w="100vw"
        bg="white"
        zIndex={2}
        border="1px"
        borderColor="gray.200"
      >
        <Box p={3} fontSize="4xl" textColor="#30ACFB">
          RVIS
        </Box>
      </Box>

      <Box display={"flex"}>
        <Box pt="50%" m="auto" fontSize={"6xl"} textColor="#30ACFB">
          <i className="ri-error-warning-line" />
        </Box>
      </Box>

      <Box m="auto" p="5%" textAlign={"center"}>
        <Text fontSize={"2xl"} fontWeight={"bold"} pb="10%">
          Unsupported Device
        </Text>
        <Text pb="5%">
          In order to participate in rapid visual categorization research,
          please use Chrome, FireFox, or Edge on a desktop device.{" "}
        </Text>
        <Text>
          Safari is currently <strong>not</strong> supported.
        </Text>
      </Box>
    </>
  );
};

export default Mobile;
