import React, { useState, useEffect } from "react";
import {
  Avatar,
  Text,
  Card,
  Fade,
  Flex,
  SlideFade,
  Heading,
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
} from "@chakra-ui/react";
import { Gradient } from "typescript-color-gradient";

const Leaderboard = (props) => {
  const [topUsers, setTopUsers] = useState([]);
  const backend = process.env.REACT_APP_IP;
  let bgColor = "#30ACFB";
  const gradientArray = new Gradient().setGradient("#30ACFB").getColors();

  useEffect(() => {
    fetch(backend + "topUsers", {
      method: "get",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res !== "no users found" && res) {
          if (res.topUsers) {
            setTopUsers(res.topUsers);
          }
        }
      })
      .catch(console.error);
  }, [setTopUsers]);

  const transition = () => {
    props.hideself(false);
    if (window.sessionStorage.getItem("user")) {
      props.transition(true);
    } else {
      props.home(true);
    }
  };

  return (
    <>
      <Box id="about" className={`${props.shown ? "" : "active"} show`}>
        <SlideFade in={true} delay={0.2}>
          <Text mt="10vh" id="leaderboard-h1">
            Leaderboard (Top 10)
          </Text>
        </SlideFade>
        <Fade in={true}>
          {topUsers.length > 0 ? (
            topUsers.map((user, key) => (
              <SlideFade in={true} delay={0.05 * key} key={key}>
                <Flex alignItems="center" className="cards">
                  <Text
                    w="5%"
                    fontWeight="bold"
                    paddingBottom="3"
                    fontSize="lg"
                  >
                    {key + 1}
                  </Text>
                  <Card
                    w="60%"
                    paddingX={{ base: "3", md: "5" }}
                    paddingY="3"
                    rounded="full"
                    marginBottom="5"
                    display="flex"
                    boxShadow="none"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Flex flexDirection="row" alignItems="center">
                      <Avatar
                        marginRight={{ base: "2", md: "4" }}
                        name={user.username}
                        size="lg"
                        // bgColor={gradientArray[topUsers.indexOf(user)]}
                      />
                      <Text margin="0" fontSize="lg">
                        {user.username.length < 20
                          ? user.username
                          : user.username.slice(0, 20) + "..."}
                      </Text>
                    </Flex>
                    <Text
                      color="rgb(48,172,251)"
                      fontWeight="bold"
                      margin="0"
                      fontSize="lg"
                    >
                      {user.points.toLocaleString()}
                    </Text>
                  </Card>
                </Flex>
              </SlideFade>
            ))
          ) : (
            <p id="user-alert">No users found</p>
          )}
        </Fade>
        {/* <button id="back-btn" onClick={transition}>
          Back home
        </button> */}
      </Box>
    </>
  );
};

export default Leaderboard;
