import { React, useEffect, useState } from "react";
import {
  SlideFade,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  Button,
  Box,
  Highlight,
} from "@chakra-ui/react";
import { Fade } from "react-awesome-reveal";
import "remixicon/fonts/remixicon.css";

const Home = (props) => {
  const transition1 = () => {
    props.hideself(false);
    props.transition(true);
  };

  const seeLeaderboard = () => {
    props.hideself(false);
    props.leaderboard(true);
  };

  const [scrollBehavior] = useState("inside");
  const { isOpen, onClose } = useDisclosure({
    defaultIsOpen: !window.sessionStorage.getItem("consent"),
  });

  const handleAgree = (e) => {
    e.preventDefault();
    window.sessionStorage.setItem("consent", "agreed");
    // console.log(window.sessionStorage.getItem("consent"));
    props.setConsent(true);
    onClose();
  };

  return (
    <>
      <Box
        id="about"
        className={`${props.shown ? "" : "active"} show`}
        // bgGradient="linear(to-b, blue.100, #ffffff)"
      >
        <Modal
          closeOnOverlayClick={false}
          closeOnEsc={false}
          isOpen={isOpen}
          onClose={onClose}
          size="2xl"
          scrollBehavior={scrollBehavior}
        >
          <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
          <ModalContent>
            <ModalHeader>
              <Text color="#30ACFB" fontSize="3xl" as="b">
                BROWN UNIVERSITY
              </Text>
              <Text fontSize="2xl">CONSENT FOR RESEARCH PARTICIPATION</Text>
              <Text>
                Human-Level Neuromorphic Artificial Visual System Last Revised
                September 28th, 2023
              </Text>
            </ModalHeader>
            <ModalBody>
              <Text fontSize="xl">
                You are invited to take part in a Brown University research
                study. Your participation is voluntary.
              </Text>
              <br />
              <Text as="b" fontSize="2xl">
                RESEARCHER:
              </Text>
              <Text fontSize="xl">Thomas R. Serre, PhD</Text>
              <br />
              <Text as="b" fontSize="2xl">
                PURPOSE:
              </Text>
              <Text fontSize="xl">
                The study is about measuring human performance in visual
                recognition tasks. You are being asked to be in this study
                because we seek to gain a basic understanding of how the visual
                system rapidly interprets and organizes visual scenes.
              </Text>
              <br />
              <Text as="b" fontSize="2xl">
                PROCEDURES:
              </Text>
              <Text fontSize="xl">
                You will be asked to view images containing objects and click on
                parts of these objects that you think are important for
                recognizing them. You will be asked questions evaluating your
                visual perception of the image.
              </Text>
              <br />
              <Text as="b" fontSize="2xl">
                TIME INVOLVED:
              </Text>
              <Text fontSize="xl">
                There is no minimal time to play the game. You can stop playing
                whenever you would like. You can also continue playing for as
                long as you would like.
              </Text>
              <br />
              <Text as="b" fontSize="2xl">
                COMPENSATION:
              </Text>
              <Text fontSize="xl">
                You will not receive compensation for your time. By
                participating you will have the opportunity to win a gift card.
                Five gift cards will be drawn each week, and you can win one of
                these if you achieve a top-five score for the week. You can
                compete for a new gift card every week.
              </Text>
              <br />
              <Text as="b" fontSize="2xl">
                RISKS:
              </Text>
              <Text fontSize="xl">
                There are no anticipated risks for the participants in the
                current study.
              </Text>
              <br />
              <Text as="b" fontSize="2xl">
                BENEFITS:
              </Text>
              <Text fontSize="xl">
                You may not directly benefit from being in this research study.
                However, data from this study may improve our knowledge of how
                the brain processes visual scenes. The researchers intend to use
                data from this study for research projects that may appear in
                scientific conferences and journals.
              </Text>
              <br />
              <Text as="b" fontSize="2xl">
                CONFIDENTIALITY:
              </Text>
              <Text fontSize="xl">
                Your participation in this study is confidential. By signing
                this document, you permit us to use anonymized information for
                scientific purposes including teaching and/or publication. You
                may opt-in to provide your email so that we can contact you if
                you win a prize, and this data will be stored securely on Google
                Cloud Storage. We will never publish identifiable data collected
                from this study. You always have the option to delete your
                personally identifiable email from your profile on our website.
                Brown University staff sometimes review studies like this one to
                make sure they are being done safely and correctly. If a review
                of this study takes place, your records may be examined. The
                reviewers will protect your confidentiality.
                <br />
                Anonymized data may or may not be used and/or shared for future
                research.
              </Text>
              <br />
              <Text as="b" fontSize="2xl">
                VOLUNTARY:
              </Text>
              <Text fontSize="xl">
                You do not have to be in this study if you do not want to be.
                Even if you decide to be in this study, you can change your mind
                and stop at any time.
              </Text>
              <br />
              <Text as="b" fontSize="2xl">
                CONTACT INFORMATION:
              </Text>

              <Text fontSize="xl">
                If you have any questions about your participation in this
                study, you can email serre-psychophysics@brown.edu.
              </Text>
              <br />
              <Text as="b" fontSize="2xl">
                YOUR RIGHTS:
              </Text>
              <Text fontSize="xl">
                If you have questions about your rights as a research
                participant, you can contact Brown University’s Human Research
                Protection Program at 401-863-3050 or email them at
                IRB@Brown.edu.
              </Text>
              <br />
              <Text as="b" fontSize="2xl">
                CONSENT TO PARTICIPATE:
              </Text>
              <Text fontSize="xl">
                Clicking 'I agree' below indicates that you have read and
                understood the information in this document, are above the age
                of 18, and that you agree to volunteer as a research participant
                for this study. This does not waive your legal rights. You can
                print a copy of the consent form or save this browser window for
                your own record.
              </Text>
              {/* <Text color="#30ACFB" fontSize="5xl" as="b">
                Consent
              </Text>
            </ModalHeader>
            <ModalBody>
              <Text fontSize="xl">
                We need your consent to use your data to develop better AI:
              </Text>
              <br />
              <Text as="b" fontSize="3xl">
                Purpose of research:
              </Text>
              <Text fontSize="xl">
                You are being asked to participate in a voluntary Brown
                University research study to measure human performance in visual
                recognition tasks. In this experiment, we seek to gain a basic
                understanding of how the visual system rapidly interprets and
                organizes visual scenes.
              </Text>
              <br />
              <Text as="b" fontSize="3xl">
                Inclusion criteria:
              </Text>
              <Text fontSize="xl">
                You must be 18 years or older to participate in this experiment.
                You must be a native English speaker.
              </Text>
              <br />
              <Text as="b" fontSize="3xl">
                Behavioral procedures:
              </Text>
              <Text fontSize="xl">
                The task involves viewing images shown very rapidly and
                detecting whether specific objects or animals were present in
                the images.
              </Text>
              <br />
              <Text as="b" fontSize="3xl">
                Time required:
              </Text>
              <Text fontSize="xl">
                There is no minimal time to play the game. You can stop playing
                whenever you would like. You can also continue playing for up to
                10 hours.
              </Text>
              <br />
              <Text as="b" fontSize="3xl">
                Benefits:
              </Text>
              <Text fontSize="xl">
                There are no direct benefits for participating in this study.
                However, data from this study may improve our knowledge of the
                how the brain processes visual objects and scenes. The
                researchers intend to use data from this study for research
                projects that may appear in scientific conferences and journals.
              </Text>
              <br />
              <Text as="b" fontSize="3xl">
                Compensation:
              </Text>
              <Text fontSize="xl">
                By participating you will have the opportunity to win a gift
                card. Five gift cards will be drawn each month.
              </Text>
              <br />
              <Text as="b" fontSize="3xl">
                Participant risks:
              </Text>
              <Text fontSize="xl">
                There are no anticipated risks for the participants in the
                current study.
              </Text>
              <br />
              <Text as="b" fontSize="3xl">
                Confidentiality:
              </Text>
              <Text fontSize="xl">
                Your participation in this study is confidential. By creating an
                account and agreeing to participate, you permit us to use
                anonymized information for scientific purposes including
                teaching and/or publication.
              </Text>
              <br />
              <Text as="b" fontSize="3xl">
                Participation and withdrawal:
              </Text>
              <Text fontSize="xl">
                You do not have to participate, and you are free to withdraw at
                any time during the course of the study by closing the browser.
              </Text>
              <br />
              <Text as="b" fontSize="3xl">
                Contact:
              </Text>
              <Text fontSize="xl">
                If you have questions or concerns about this research, please
                call: (401) 863-1148 (or email serrepsychophysics@brown.edu).
              </Text>
              <Text fontSize="xl">
                If you have questions in regards to your rights as a
                participant, please contact Brown University's Human Research
                Protection Program: Box 1986 Brown University tel: (401)
                863-3050 toll-free: (866) 309-2095
              </Text>
              <br />
              <Text fontSize="xl">
                By clicking 'I agree' below indicates that you have understood
                the information about the experiment and consent to your
                participation. The participation is voluntary and you may refuse
                to answer certain questions on the questionnaire and withdraw
                from the study at any time with no penalty. This does not waive
                your legal rights. You can print a copy of the consent form or
                save this browser window for your own record. If you have
                further questions related to this research, please contact the
                researchers.
              </Text> */}
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="green" mr={3} onClick={handleAgree}>
                I agree
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
        <SlideFade in={true} delay={0.2}>
          <Box color="#092232">
            <Box id="about-h1" fontSize="7xl">
              Welcome to
            </Box>
            <Box id="rvc" fontSize="7xl">
              Rapid Visual Categorization!
            </Box>
          </Box>

          <button
            className="signup-btn"
            onClick={() => {
              transition1();
            }}
          >
            Start playing
          </button>

          <SlideFade in={true} delay={0.4} id="about-text">
            <Fade>
              <Text id="about-h2" fontSize="3xl">
                ABOUT <i className="ri-information-line" />
              </Text>
              <div className="about-w-btn">
                <Box id="about-p" fontSize="2xl">
                  Rapid visual categorization involves seeing an image for a
                  fraction of a second and immediately determining what was in
                  it. In this game, your goal is to describe the image to the
                  best of your ability!
                  <br />
                  <br />
                  By playing rapid visual categorization, you're helping us
                  improve artificial intelligence (AI) systems, which try to
                  mimic natural vision using computational techniques.
                  <br />
                  <br />
                  Every time you play the game, you're providing researchers
                  with information about the complexities of the human visual
                  system. You are helping us understand how and why humans are
                  able to process what is seen so quickly!
                  <br />
                  <br />
                  Every month, we give cash prizes to the top players on the{" "}
                  <button id="inline-btn" onClick={seeLeaderboard}>
                    leaderboard!
                  </button>
                  <br />
                  <br />
                  <button
                    className="rtrn-btn"
                    onClick={() => {
                      transition1();
                    }}
                  >
                    <strong>PLAY NOW TO WIN CASH PRIZES!</strong>
                  </button>
                </Box>
              </div>
            </Fade>
            <Fade>
              <Text id="about-h2" fontSize="3xl">
                MOTIVATION <i className="ri-lightbulb-line" />
              </Text>
              <Box id="about-p" fontSize="2xl">
                Our visual system is a remarkable mystery of nature. Regardless
                of whether an object is blurry, upside down, or transformed in a
                way we have never seen before, we can recognize it nearly
                instantly. Even the latest and greatest AI systems like OpenAI's
                GPT-4 (ChatGPT's successor that can process both vision and
                language) are no match for human vision, across a variety of
                diverse tasks and metrics.
                <br />
                <br />
                We are investigating the contributions of feedforward and
                feedback mechanisms in visual perception using a
                well-established paradigm called rapid visual categorization.
                Decades of neuroscience, psychology, and neurophysiology
                research has shown that the human brain can process visual
                stimuli and detect objects or animals in as little as 20
                milliseconds. By showing images for very short presentation
                times and eliciting quick behavioral responses, we are able to
                uncover the inner workings of the human visual system.
              </Box>
            </Fade>
            <Fade>
              <Text id="about-h2" fontSize="3xl">
                HOW WILL MY DATA BE USED? <i className="ri-pie-chart-2-line" />
              </Text>
              <Box id="about-p" fontSize="2xl">
                We are using the data from this experiment to train a set of AI
                models that are more aligned with biology than anything the
                field has seen. Beyond creating newer and more computationally
                efficient AI, this data can be used to robustly assess the
                alignment between existing computer vision models and humans.
                <br></br>
                <br></br>
                You are in control of your personal information. You always have
                the right to delete your account, permanently removing your
                email and personally identifiable data from all of our
                databases.
              </Box>
            </Fade>

            {/* <Fade>
              <Box id="refs" fontSize="xl">
                [1] Geirhos, Robert, et al. “Generalisation in Humans and Deep
                Neural Networks.” 32nd Conference on Neural Information
                Processing Systems, 2018,
                https://proceedings.neurips.cc/paper/2018/file/0937fb5864ed06ffb59ae5f9b5ed67a9-Paper.pdf.
                <br />
                [2] Metz, Cade, and Keith Collins. “10 Ways GPT-4 Is Impressive
                but Still Flawed.” The New York Times, 14 Mar. 2023,
                www.nytimes.com/2023/03/14/technology/openai-new-gpt4.html.
                <br />
                [3] Thorpe, Simon, et al. “Speed of Processing in the Human
                Visual System.” Nature, vol. 381, no. 6582, 6 June 1996, pp.
                520-522, https://doi.org/10.1038/381520a0.
                <br />
                [4] Eberhardt, Sven, et al. “How Deep Is the Feature Analysis
                Underlying Rapid Visual Categorization?” Advances in Neural
                Information Processing Systems, 3 June 2016,
                https://papers.nips.cc/paper_files/paper/2016/hash/42e77b63637ab381e8be5f8318cc28a2-Abstract.html.
              </Box>
            </Fade> */}
          </SlideFade>
        </SlideFade>
      </Box>
    </>
  );
};

export default Home;
