import "./App.css";
import Module from "./module";
import Intro from "./components/intro";
import Directions from "./components/directions";
import PlayAgain from "./components/playAgain";
import MultipleChoice from "./components/multipleChoice";
import Fail from "./components/fail";
import Flagged from "./components/flagged";
import { useState } from "react";
import fix from "./fix.png";
import { useEffect, useRef } from "react";
import Continue from "./components/continue";
import PracticeDir from "./components/practice_dir";
import MainDir from "./components/main_dir";
import Reminder from "./components/reminder";
import SignUp from "./components/signup";
import Home from "./components/home";
import { ChakraProvider, Box, Fade } from "@chakra-ui/react";
import SignIn from "./components/signin";
import Returning from "./components/returning";
import Leaderboard from "./components/leaderboard";
import Profile from "./components/profile";
import NavBar from "./components/navbar";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import Stats from "./components/stats";
import Mobile from "./components/mobile";
import Safari from "./components/safari";
import PracticeDir2 from "./components/practice_dir2";
import Response from "./components/response";
// const animal = Math.random(); // randomly decides keybind for multiple choice

function App() {
  const [seeSignUp, setSeeSignUp] = useState(false);
  const [seeIntro, setSeeIntro] = useState(false); //whether intro popup is visible
  const [seeDirections, setSeeDirections] = useState(false); //whether directions popup is visible
  const [seeImage, setSeeImage] = useState(false); //whether pre-stimuli or stimuli images are visible
  const [seeMultipleChoice, setSeeMultipleChoice] = useState(false); //whether multiple choice popup is visible
  const [seePlayAgain, setSeePlayAgain] = useState(false); //whether play again choice popup is visible
  const [seeFlagged, setSeeFlagged] = useState(false);
  const [seeNavBar, setSeeNavBar] = useState(true);
  const [imagePath, setImagePath] = useState(
    "https://conservationnation.org/wp-content/uploads/2020/02/bengal-tiger-hero.jpg"
  ); //path of displayed image
  const [duration, setDuration] = useState(0); //duration stimuli is shown
  const [choices] = useState(["animal", "non-animal"]); //choices displayed to user in multiple choice
  const [label, setLabel] = useState(""); //label of stimuli image
  const [stimuliPath, setStimuliPath] = useState(""); //image path of stimuli image
  const [startTimer, setStartTimer] = useState(null);
  const [seeFail, setSeeFail] = useState(false); // server error message screen
  const [seeVideo, setSeeVideo] = useState(false); // whether video is visible
  const [videoPath, setVideoPath] = useState(null); // path to video
  const [emptyCount, setEmptyCount] = useState(0);
  const [trialCount, setTrialCount] = useState(0);
  const [isCatchTrial, setIsCatchTrial] = useState(false);
  const [seeContinue, setSeeContinue] = useState(false);
  const [seePracticeDir, setSeePracticeDir] = useState(false);
  const [isPractice, setIsPractice] = useState(false);
  const [seeMainDir, setSeeMainDir] = useState(false);
  const [seeReminder, setSeeReminder] = useState(false);
  const [synsetID, setSynsetID] = useState(null);
  const [seeHome, setSeeHome] = useState(
    window.sessionStorage.getItem("user") == null
  );
  const [seeSignIn, setSeeSignIn] = useState(false);
  const [seeReturning, setSeeReturning] = useState(
    window.sessionStorage.getItem("user") != null
  );
  const [seeLeaderboard, setSeeLeaderboard] = useState(false);
  const [user, setUser] = useState();
  const [streak, setStreak] = useState(0);
  const [bestStreak, setBestStreak] = useState(0);
  const [corr, setCorr] = useState(0);
  const [practiceCount, setPracticeCount] = useState(0);
  const [seeProfile, setSeeProfile] = useState(false);
  const [imgID, setImgID] = useState(null);
  const backend = process.env.REACT_APP_IP;
  const practiceImageOrder = useRef([...Array(10).keys()]);
  const [catchTrialNums, setCatchTrialNums] = useState([]);
  const [consent, setConsent] = useState(false);
  const [inTrial, setInTrial] = useState(false);
  const [quit, setQuit] = useState(false);
  const [reload, setReload] = useState(false);
  const [animal, setAnimal] = useState(0);
  const isSafari = window.safari !== undefined;
  const [seePracticeDir2, setSeePracticeDir2] = useState(false);
  const [seeResponse, setSeeResponse] = useState(false);

  // let f, j; // TODO remove repeat code
  const f = useRef();
  const j = useRef();

  useEffect(() => {
    if (animal < 0.5) {
      f.current = choices[0];
      j.current = choices[1];
    } else {
      j.current = choices[0];
      f.current = choices[1];
    }
  }, [animal, choices]);

  useEffect(() => {
    setAnimal(window.sessionStorage.getItem("keybind") ?? Math.random());
  }, [user]);

  const { executeRecaptcha } = useGoogleReCaptcha();

  // const handleReCaptchaVerify = useCallback(async () => {
  //   if (!executeRecaptcha) {
  //     return;
  //   }

  //   const token = await executeRecaptcha("block_responses");

  //   const submitEnquiryForm = (gReCaptchaToken) => {
  //     fetch(backend + "verifyUser", {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json, text/plain, */*",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify({
  //         gRecaptchaToken: gReCaptchaToken,
  //       }),
  //     })
  //       .then((res) => res.json())
  //       .then((res) => {
  //         // console.log(res);
  //       });
  //   };

  //   submitEnquiryForm(token);
  // }, [backend, executeRecaptcha]);

  /**
   * FPS measurement code
   */
  let stats = new Stats();

  let fps = 0;
  let max = 0;

  function animate() {
    fps = stats.getFPS();
    max = Math.max(fps, max);
    requestAnimationFrame(animate);
  }

  requestAnimationFrame(animate);

  const shuffle = (array) => {
    return array.sort(() => Math.random() - 0.5);
  };

  const genCatchTrialNums = () => {
    setCatchTrialNums([]);
    let newNums = [];
    while (newNums.length < 3) {
      const num = Math.floor(Math.random() * 49) + 1;
      if (newNums.indexOf(num) === -1) {
        newNums.push(num);
      } else {
        newNums.push(0);
      }
    }
    setCatchTrialNums(newNums);
  };

  useEffect(() => {
    setUser(window.sessionStorage.getItem("user") ?? null);
    setAnimal(window.sessionStorage.getItem("keybind") ?? Math.random());
    // console.log(
    //   "useeffect/reload/storage:",
    //   window.sessionStorage.getItem("keybind")
    // );
    practiceImageOrder.current = shuffle(practiceImageOrder.current);
    genCatchTrialNums();
  }, []);

  const drawPracticeVideo = async () => {
    setSeeMultipleChoice(false);
    if (practiceCount % 19 === 0 && practiceCount !== 0) {
      setIsPractice(false);
      setSeeMainDir(true);
    } else if (
      practiceCount % 9 === 0 &&
      practiceCount !== 0 &&
      practiceCount % 18 !== 0
    ) {
      setSeePracticeDir2(true);
      practiceImageOrder.current = shuffle([...Array(10).keys()]);
    } else {
      let foundError = false;
      let index = practiceImageOrder.current.pop();
      const body = JSON.stringify({
        index: index,
      });
      let endpoint = "getShortPracticeVideo";
      if (practiceCount < 10) {
        endpoint = "getLongPracticeVideo";
      }

      try {
        const response = await fetch(backend + endpoint, {
          method: "put",
          body: body,
          headers: { "Content-Type": "application/json" },
        });
        const result = await response.json();
        let path = result.videoTitle;
        let video = path.split("/");
        let IDs = video[2].substring(0, video[2].lastIndexOf(".")).split("_");
        let synID = IDs[1];
        setImgID(IDs[2]);
        setSynsetID(synID);
        synID = synID.split("n");
        synID = parseInt(synID[1]);
        if (synID < 2666196 || synID === 9835506) {
          setLabel("animal");
          // console.log("animal! ", synID);
        } else {
          setLabel("non-animal");
          // console.log("not ", synID);
        }
        // console.log(path);
        setVideoPath(path);
        setStimuliPath("/" + path);
        // setTimeout(() => {
        //   setSeeMultipleChoice(true);
        // }, 1000);
        // setTimeout(setSeeMultipleChoice(true), 1000);
        // setTimeout(setSeeResponse(true), 2000);
        // setTimeout(() => {
        //   setSeeVideo(false);
        //   setSeeResponse(true);
        // })
      } catch (e) {
        console.log(e);
        foundError = true;
      } finally {
        if (foundError) {
          foundError = false;
          setSeeFail(true);
        } else {
          setSeeVideo(true);
        }
      }
    }
  };

  const drawImage = async () => {
    setSeeMultipleChoice(false);
    if (practiceCount % 9 === 0 && practiceCount !== 0) {
      setIsPractice(false);
      setSeeMainDir(true);
    } else {
      //get image path of stimuli
      let stimuli_path =
        "https://conservationnation.org/wp-content/uploads/2020/02/bengal-tiger-hero.jpg";
      let foundError = false;
      let index = practiceImageOrder.current.pop();
      const body = JSON.stringify({
        index: index,
      });
      try {
        const response = await fetch(backend + "getPracticeImage", {
          method: "put",
          body: body,
          headers: { "Content-Type": "application/json" },
        });
        const result = await response.json();
        let folders = result.imageLink.split("/");
        let synsetID = folders[2];
        let numID = Number(synsetID.substring(1, synsetID.length));
        if (numID < 2666196 || numID === 9835506) {
          setLabel("animal");
        } else {
          setLabel("non-animal");
        }
        stimuli_path =
          "https://storage.googleapis.com/serrelab/" + result.imageLink;
        setStimuliPath(stimuli_path);
      } catch (e) {
        console.log(e);
        foundError = true;
      } finally {
        if (foundError) {
          foundError = false;
          setSeeFail(true);
        } else {
          let durations = [600, 650, 700, 750, 800, 850, 900, 950, 1000];
          let durationtemp =
            durations[Math.floor(Math.random() * durations.length)];
          setDuration(durationtemp);
          setSeeImage(true);
          setImagePath(fix);
          setTimeout(() => {
            setImagePath(stimuli_path);
            // setSeeMultipleChoice(true);
            // setSeeResponse(true);
          }, 1500);
          setTimeout(() => {
            setSeeImage(false);
            setStartTimer(Date.now());
          }, 1500 + durationtemp);
        }
      }
    }
  };

  let timer = setTimeout(0);
  let timer2 = setTimeout(0);

  const catchTrial = async () => {
    let foundError = false;
    try {
      const response = await fetch(backend + "getCatchVideo", {
        method: "put",
        headers: { "Content-Type": "application/json" },
      });
      const result = await response.json();
      let path = result.videoTitle;
      let video = path.split("/");
      let IDs = video[2].substring(0, video[2].lastIndexOf(".")).split("_");
      let synID = IDs[1];
      setImgID(IDs[2]);
      setSynsetID(synID);
      synID = synID.split("n");
      synID = parseInt(synID[1]);
      if (synID < 2666196 || synID == 9835506) {
        setLabel("animal");
      } else {
        setLabel("non-animal");
      }
      setVideoPath(path);
      setStimuliPath("/" + path);
      setTimeout(() => {
        // setSeeMultipleChoice(true);
        // setSeeResponse(true);
      }, 1500);
    } catch (e) {
      foundError = true;
    } finally {
      if (foundError) {
        foundError = false;
        setSeeFail(true);
      } else {
        setSeeVideo(true);
      }
    }
  };

  useEffect(() => {
    clearTimeout(timer);
    clearTimeout(timer2);
  }, [trialCount]);

  // const drawVideo = async () => {
  //   setSeeMultipleChoice(false);
  //   setIsCatchTrial(true);
  //   catchTrial();
  // };

  const drawVideo = async () => {
    setIsCatchTrial(false);
    setSeeMultipleChoice(false);
    // setSeeResponse(false);
    // console.log(catchTrialNums);
    if (catchTrialNums.indexOf(trialCount) !== -1) {
      // if (true) {
      setIsCatchTrial(true);
      catchTrial();
    } else if (trialCount % 49 === 0 && trialCount !== 0) {
      updateData();
      setSeeContinue(true);
      genCatchTrialNums();
    } else {
      let foundError = false;
      try {
        const response = await fetch(backend + "getVideo", {
          method: "put",
          headers: { "Content-Type": "application/json" },
        });
        const result = await response.json();
        let path = result.videoTitle;
        let video = path.split("/");
        let IDs = video[2].substring(0, video[2].lastIndexOf(".")).split("_");
        let synID = IDs[1];
        setImgID(IDs[2]);
        setSynsetID(synID);
        synID = synID.split("n");
        synID = parseInt(synID[1]);
        if (synID < 2666196 || synID === 9835506) {
          setLabel("animal");
        } else {
          setLabel("non-animal");
        }
        setVideoPath(path);
        setStimuliPath("/" + path);
        setTimeout(() => {
          // setSeeMultipleChoice(true);
          // setSeeResponse(true);
        }, 1500);
      } catch (e) {
        foundError = true;
      } finally {
        if (foundError) {
          foundError = false;
          setSeeFail(true);
        } else {
          setSeeVideo(true);
        }
      }
    }
  };

  const handleVideoEnd = () => {
    setSeeVideo(false);
    setTimeout(setSeeResponse(true), 500);
    // setSeeResponse(true);
    setStartTimer(Date.now());
    // setSeeMultipleChoice(true);
  };

  const updateData = async () => {
    let total = trialCount;
    let points = corr * 100;

    const body = JSON.stringify({
      username: window.sessionStorage.getItem("user"),
      points: points,
      corr: corr,
      total: total,
    });
    const response = await fetch(backend + "updateUserStats", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: body,
    });
    const result = await response.json();
    // TODO : add error catching for update
    // miscounting number of correct trials
    // TODO: if recaptcha  < 0.5, set seeFlagged to true
    if (animal < 0.5) {
      f.current = choices[0];
    } else {
      f.current = choices[1];
    }
    let blockBody = JSON.stringify({
      user: window.sessionStorage.getItem("user"),
      mapping: f.current,
      correct: corr,
      flagged: seeFlagged,
      fps: fps,
    });
    const blockResponse = await fetch(backend + "addBlock", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: blockBody,
    });
    const blockResult = await blockResponse.json();
    // TODO : handle error
  };

  useEffect(() => {
    // handleReCaptchaVerify();
  }, [corr, trialCount]);

  return (
    <ChakraProvider>
      <div className="App">
        {!isSafari && (
          <div id="content-desktop">
            {seeNavBar && (
              <NavBar
                hideself={setSeeNavBar}
                seeHome={setSeeHome}
                seeLeaderboard={setSeeLeaderboard}
                seeSignIn={setSeeSignIn}
                seeSignUp={setSeeSignUp}
                seeProfile={setSeeProfile}
                seeReturning={setSeeReturning}
                user={window.sessionStorage.getItem("user")} // TODO: fix - silly
                reload={reload}
              />
            )}
            {inTrial && (
              <Fade in={inTrial}>
                <Box
                  position="absolute"
                  zIndex={-1}
                  bg="rgb(128,128,128)"
                  h="100vh"
                  w="100vw"
                  overflow-y="hidden"
                  overflow-x="hidden"
                ></Box>
                {/* <div className="fill-window"></div> */}
              </Fade>
            )}

            {seeProfile && (
              <Profile
                shown={seeProfile}
                transition={setSeeReturning}
                seeNav={setSeeNavBar}
                hideself={setSeeProfile}
                user={window.sessionStorage.getItem("user")} // TODO: fix this - silly
                reload={reload}
                setReload={setReload}
              />
            )}
            {seeHome && (
              <Home
                shown={seeHome}
                transition={setSeeSignIn}
                hideself={setSeeHome}
                leaderboard={setSeeLeaderboard}
                navbar={setSeeNavBar}
                consent={consent}
                setConsent={setConsent}
              ></Home>
            )}
            {seeSignIn && (
              <Module shown={seeSignIn}>
                <SignIn
                  transition1={setSeeReturning}
                  transition2={setSeeSignUp}
                  hideself={setSeeSignIn}
                  seeNav={setSeeNavBar}
                  user={setUser}
                ></SignIn>
              </Module>
            )}
            {seeSignUp && (
              <SignUp
                shown={seeSignUp}
                transition1={setSeeDirections}
                transition2={setSeeSignIn}
                hideself={setSeeSignUp}
                seeNav={setSeeNavBar}
                user={setUser}
                practice={setIsPractice}
                setInTrial={setInTrial}
              />
            )}
            {seeReturning && (
              <Returning
                shown={seeReturning}
                transition1={setSeeMainDir}
                transition2={setSeeLeaderboard}
                transition3={setSeeProfile}
                hideself={setSeeReturning}
                seeNav={setSeeNavBar}
                user={window.sessionStorage.getItem("user")}
                reminder={setSeeReminder}
                setInTrial={setInTrial}
              ></Returning>
            )}
            {seeLeaderboard && (
              <Leaderboard
                shown={seeLeaderboard}
                transition={setSeeReturning}
                hideself={setSeeLeaderboard}
                home={setSeeHome}
                user={window.sessionStorage.getItem("user")}
              ></Leaderboard>
            )}
            {seeReminder && (
              <div>
                <Reminder
                  shown={seeReminder}
                  choices={choices}
                  keybind={animal}
                  seeReminder={setSeeReminder}
                  seeMultipleChoice={setSeeMultipleChoice}
                  seeContinue={setSeeContinue}
                  seeImage={setSeeImage}
                  seeVideo={setSeeVideo}
                  inTrial={setInTrial}
                  seeMainDir={setSeeMainDir}
                  seeDirections={setSeeDirections}
                  seePracticeDir={setSeePracticeDir}
                  seeNav={setSeeNavBar}
                  seeReturning={setSeeReturning}
                  quit={quit}
                  setQuit={setQuit}
                ></Reminder>
              </div>
            )}
            {seeIntro && (
              <Module shown={seeIntro}>
                <Intro
                  hideself={setSeeIntro}
                  transition={setSeeDirections}
                ></Intro>
              </Module>
            )}
            {seeDirections && (
              <Module shown={seeDirections}>
                <Directions
                  hideself={setSeeDirections}
                  transition={setSeePracticeDir}
                  choices={choices}
                  keybind={animal}
                  showReminder={setSeeReminder}
                  setInTrial={setInTrial}
                ></Directions>
              </Module>
            )}
            {seePracticeDir && (
              <Module shown={seePracticeDir}>
                <PracticeDir
                  hideself={setSeePracticeDir}
                  transition={drawPracticeVideo}
                  choices={choices}
                  keybind={animal}
                ></PracticeDir>
              </Module>
            )}
            {seePracticeDir2 && (
              <Module shown={seePracticeDir2}>
                <PracticeDir2
                  hideself={setSeePracticeDir2}
                  transition={drawPracticeVideo}
                  choices={choices}
                  keybind={animal}
                ></PracticeDir2>
              </Module>
            )}
            {seeMainDir && (
              <Module shown={seeMainDir}>
                <MainDir
                  hideself={setSeeMainDir}
                  transition={drawVideo}
                  choices={choices}
                  keybind={animal}
                  setInTrial={setInTrial}
                ></MainDir>
              </Module>
            )}

            {seeImage && <img src={imagePath} alt="" width="40%"></img>}
            {seeVideo && (
              <video
                src={"https://storage.googleapis.com/serrelab/" + videoPath}
                width="40%"
                autoPlay
                onEnded={handleVideoEnd}
              ></video>
            )}
            {seeResponse && (
              <Module shown={seeResponse}>
                <Response
                  hideself={setSeeResponse}
                  practice={isPractice}
                  drawVideo={drawVideo}
                  drawPracticeVideo={drawPracticeVideo}
                  trialCount={trialCount}
                  updateTrialCount={setTrialCount}
                  practiceCount={practiceCount}
                  updatePracticeCount={setPracticeCount}
                />
              </Module>
            )}
            {seeMultipleChoice && (
              <div>
                <MultipleChoice
                  shown={seeMultipleChoice}
                  imagePath={videoPath}
                  duration={duration}
                  choices={choices}
                  hideself={setSeeMultipleChoice}
                  count={emptyCount}
                  counter={setEmptyCount}
                  transition1={drawVideo}
                  transition2={drawImage}
                  practiceTransition={drawPracticeVideo}
                  flagged={setSeeFlagged}
                  catch={isCatchTrial}
                  practice={isPractice}
                  label={label}
                  stimuliPath={stimuliPath}
                  startTimer={startTimer}
                  keybind={animal}
                  synsetID={synsetID}
                  imgID={imgID}
                  corr={corr}
                  updateCorr={setCorr}
                  streak={streak}
                  updateStreak={setStreak}
                  bestStreak={bestStreak}
                  updateBestStreak={setBestStreak}
                  trialCount={trialCount}
                  updateTrialCount={setTrialCount}
                  practiceCount={practiceCount}
                  updatePracticeCount={setPracticeCount}
                  setSeeVideo={setSeeVideo}
                  setSeeImage={setSeeImage}
                  quit={quit}
                  setQuit={setQuit}
                ></MultipleChoice>
              </div>
            )}
            {seePlayAgain && (
              <Module shown={seePlayAgain}>
                <PlayAgain
                  hideself={setSeePlayAgain}
                  transition={drawVideo}
                ></PlayAgain>
              </Module>
            )}
            {seeContinue && (
              <Module shown={seeContinue}>
                <Continue
                  hideself={setSeeContinue}
                  transition={drawVideo}
                  transition2={setSeeReturning}
                  seeNav={setSeeNavBar}
                  toggleReminder={setSeeReminder}
                  corr={corr}
                  total={trialCount}
                  updateTotal={setTrialCount}
                  bestStreak={bestStreak}
                  updateCorr={setCorr}
                  updateStreak={setStreak}
                  updateBestStreak={setBestStreak}
                  setInTrial={setInTrial}
                ></Continue>
              </Module>
            )}
            {seeFail && (
              <Module shown={seeFail}>
                <Fail
                  hideself={setSeeFail}
                  // transition={drawImage}
                  transition={drawPracticeVideo}
                ></Fail>
              </Module>
            )}
            {seeFlagged && (
              <Module shown={seeFlagged}>
                <Flagged />
              </Module>
            )}
          </div>
        )}
        {isSafari && (
          <div id="content-desktop">
            <Safari />
          </div>
        )}
        <div id="content-mobile">
          <Mobile />
        </div>
      </div>
    </ChakraProvider>
  );
}

export default App;
