import React from "react";
import { SlideFade } from "@chakra-ui/react";
import { Avatar } from "@chakra-ui/react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Text,
  HStack,
  Button,
} from "@chakra-ui/react";
import { Fade } from "react-awesome-reveal";
import NavBar from "./navbar";
import "remixicon/fonts/remixicon.css";

const Returning = (props) => {
  const startTrials = () => {
    props.reminder(true);
    props.setInTrial(true);
    props.seeNav(false);
    props.hideself(false);
    props.transition1(true);
  };

  const seeLeaderboard = () => {
    props.hideself(false);
    props.transition2(true);
  };

  // const seeProfile = () => {
  //   props.hideself(false);
  //   props.transition3(true);
  // };

  // // TODO: This should reset the saved user name and session info

  // const signOut = () => {
  //   window.location.reload();
  // };

  return (
    <>
      <div>
        <Box
          display={"flex"}
          // bg="#30ACFB"
          // bgGradient="linear(to-b,#bde5ff, white)"
        >
          {/* <Box px={"5"} fontSize="6xl" textColor="#30ACFB">
            RVIS
          </Box> */}
          {/* <HStack spacing="10" px="5" mr="auto">
            <Button
              as="a"
              href="#about-text"
              onClick={handleAboutClick}
              variant="unstyled"
              fontSize="3xl"
              fontWeight="normal"
            >
              About
            </Button>
            <Button
              variant="unstyled"
              fontSize="3xl"
              fontWeight="normal"
              onClick={() => {
                seeLeaderboard();
              }}
            >
              Leaderboard
            </Button>
            <Button
              as="a"
              href="mailto:m.bluth@example.com"
              fontSize="3xl"
              fontWeight="normal"
              variant="unstyled"
            >
              Contact Us
            </Button>
          </HStack> */}
          {/* <HStack spacing="10" px="5">
            <Menu>
              <MenuButton display="flex" mx={5} ml="auto">
                <Avatar name={props.user} cursor="pointer" />
              </MenuButton>
              <MenuList>
                <MenuItem onClick={seeProfile}>View Profile</MenuItem>
                <MenuItem onClick={signOut}>Sign Out</MenuItem>
              </MenuList>
            </Menu>
          </HStack> */}
        </Box>

        <div id="about" className={`${props.shown ? "" : "active"} show`}>
          <SlideFade in={true} delay={0.2}>
            <Box id="about-h1" fontSize="7xl">
              Welcome back, {window.sessionStorage.getItem("user")}!
            </Box>
            <div className="rtrn-btn-div">
              <button
                className="returning-btns"
                onClick={() => {
                  startTrials();
                }}
              >
                Start playing
              </button>
              {/* <button
                className="returning-btns"
                onClick={() => {
                  seeLeaderboard();
                }}
              >
                View Leaderboard
              </button> */}
            </div>
            <SlideFade id="about-text" in={true} delay={0.4}>
              <Fade>
                <Text id="about-h2" fontSize="3xl">
                  ABOUT <i className="ri-information-line" />
                </Text>
                <Box id="about-p" fontSize="2xl">
                  Rapid visual categorization involves seeing an image for a
                  fraction of a second, and immediately determining what was in
                  it. In this game, your goal is to describe the image to the
                  best of your ability!
                  <br></br>
                  <br></br>
                  By playing rapid visual categorization, you're helping us
                  improve artificial intelligence (AI) systems, which try to
                  mimic natural vision using computational techniques.
                  <br></br>
                  <br></br>
                  Every time you play the game, you're providing researchers
                  with information about the complexities of the human visual
                  system. You're helping us understand how and why humans are
                  able to process what is seen so quickly!
                  <br></br>
                  <br></br>
                  Every month, we give cash prizes to the top players on the
                  leaderboard.
                  <br></br>
                  <br></br>
                  <strong>PLAY NOW TO WIN CASH PRIZES!</strong>
                </Box>
              </Fade>
              <Fade>
                <Text id="about-h2" fontSize="3xl">
                  MOTIVATION <i className="ri-lightbulb-line" />
                </Text>
                <Box id="about-p" fontSize="2xl">
                  Our visual system is a remarkable mystery of nature.
                  Regardless of whether an object is blurry, upside down, or
                  transformed in a way we have never seen before, we can
                  recognize it nearly instantly. Even the latest and greatest AI
                  systems like OpenAI's GPT-4 (ChatGPT's successor that can
                  process both vision and language) are no match for human
                  vision, across a variety of diverse tasks and metrics.
                  <br />
                  <br />
                  We are investigating the contributions of feedforward and
                  feedback mechanisms in visual perception using a
                  well-established paradigm called rapid visual categorization.
                  Decades of neuroscience, psychology, and neurophysiology
                  research has shown that the human brain can process visual
                  stimuli and detect objects or animals in as little as 20
                  milliseconds. By showing images for very short presentation
                  times and eliciting quick behavioral responses, we are able to
                  uncover the inner workings of the human visual system.
                </Box>
              </Fade>
              <Fade>
                <Text id="about-h2" fontSize="3xl">
                  HOW WILL MY DATA BE USED?{" "}
                  <i className="ri-pie-chart-2-line" />
                </Text>
                <Box id="about-p" fontSize="2xl">
                  We are using the data from this experiment to train a set of
                  AI models that are more aligned with biology than anything the
                  field has seen. Beyond creating newer and more computationally
                  efficient AI, this data can be used to robustly assess the
                  alignment between existing computer vision models and humans.
                  <br></br>
                  <br></br>
                  You are in control of your personal information. You always
                  have the right to delete your account, permanently removing
                  your email and personally identifiable data from all of our
                  databases.
                </Box>
              </Fade>
              {/* <Fade>
                <Box id="refs" fontSize="xl">
                  [1] Geirhos, Robert, et al. “Generalisation in Humans and Deep
                  Neural Networks.” 32nd Conference on Neural Information
                  Processing Systems, 2018,
                  https://proceedings.neurips.cc/paper/2018/file/0937fb5864ed06ffb59ae5f9b5ed67a9-Paper.pdf.
                  <br />
                  [2] Metz, Cade, and Keith Collins. “10 Ways GPT-4 Is
                  Impressive but Still Flawed.” The New York Times, 14 Mar.
                  2023,
                  www.nytimes.com/2023/03/14/technology/openai-new-gpt4.html.
                  <br />
                  [3] Thorpe, Simon, et al. “Speed of Processing in the Human
                  Visual System.” Nature, vol. 381, no. 6582, 6 June 1996, pp.
                  520-522, https://doi.org/10.1038/381520a0.
                  <br />
                  [4] Eberhardt, Sven, et al. “How Deep Is the Feature Analysis
                  Underlying Rapid Visual Categorization?” Advances in Neural
                  Information Processing Systems, 3 June 2016,
                  https://papers.nips.cc/paper_files/paper/2016/hash/42e77b63637ab381e8be5f8318cc28a2-Abstract.html.
                </Box>
              </Fade> */}
            </SlideFade>
          </SlideFade>
        </div>
      </div>
    </>
  );
};

export default Returning;
