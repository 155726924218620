import React, { useEffect, useCallback } from "react";
import { Textarea, Text } from "@chakra-ui/react";

const Response = (props) => {
  // const transition1 = () => {
  //   props.hideself(false);
  //   props.transition();
  // };

  const handleEnter = useCallback(
    (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        props.hideself(false);
        if (props.practice) {
          props.updatePracticeCount(props.practiceCount + 1);
          props.drawPracticeVideo();
        } else {
          props.updateTrialCount(props.trialCount + 1);
          props.drawVideo();
        }
      }
    },
    [props]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEnter, false);

    return () => {
      document.removeEventListener("keydown", handleEnter, false); // to prevent mem leaks
    };
  }, [handleEnter]);

  return (
    <form className="response">
      <br />
      <Textarea
        autoFocus
        mt={5}
        w="30vw"
        h="10vh"
        bg="white"
        color="black"
        resize="none"
        placeholder="Describe the image..."
      />
      <br />
      <br />
      <Text pb={5}>
        Press <strong>enter</strong> to continue
      </Text>
    </form>
  );
};

export default Response;
