import React, { useState, useEffect } from "react";
import { HStack, Avatar, Select } from "@chakra-ui/react";
import {
  Button,
  SlideFade,
  Box,
  useToast,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberDecrementStepper,
  NumberIncrementStepper,
  Input,
} from "@chakra-ui/react";

const Profile = (props) => {
  const [email, setEmail] = useState();
  const [age, setAge] = useState();
  const [gender, setGender] = useState();
  const [handedness, setHandedness] = useState();
  const [points, setPoints] = useState();
  const [user, setUser] = useState(window.sessionStorage.getItem("user"));
  const [prevUser, setPrevUser] = useState(
    window.sessionStorage.getItem("user")
  );
  const toast = useToast({ position: "top" });
  const backend = process.env.REACT_APP_IP;

  const transition = () => {
    props.hideself(false);
    props.transition(true);
  };

  const getUserStats = async () => {
    const body = JSON.stringify({
      username: window.sessionStorage.getItem("user"),
    });
    const response = await fetch(backend + "getUserStats", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: body,
    });

    const result = await response.json();
    result.row.email ? setEmail(result.row.email) : setEmail();
    if (result.row.age) {
      setAge(result.row.age);
    }
    if (result.row.gender) {
      setGender(result.row.gender);
    }
    setHandedness(result.row.handedness);
    setPoints(result.row.points);
  };

  useEffect(() => {
    getUserStats();
    // console.log(typeof window.sessionStorage.getItem("user"));
  }, []);

  const updateUserProfile = async () => {
    // console.log(typeof prevUser);
    const body = JSON.stringify({
      username: user,
      prevUser: prevUser,
      email: email,
      age: age,
      gender: gender,
      handedness: handedness,
    });
    const response = await fetch(backend + "updateUserProfile", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: body,
    });
    const result = await response.json();
    if (result === "success") {
      toast({
        title: "Success",
        description: "Your profile has been successfully updated.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      window.sessionStorage.setItem("user", user);
      setPrevUser(user);
      props.setReload(!props.reload);
      getUserStats();
    } else if (result === "user exists") {
      toast({
        title: "Error",
        description: "Username taken. Please enter new username and try again.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else {
      toast({
        title: "Error",
        description:
          "There was an error updating your profile. Please try again.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async () => {
    const body = JSON.stringify({
      username: window.sessionStorage.getItem("user"),
    });
    const response = await fetch(backend + "deleteUser", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: body,
    });
    const result = await response.json();
    if (result === "success") {
      toast({
        title: "Success",
        description: "Your profile has been successfully deleted.",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      window.sessionStorage.removeItem("user");
      window.sessionStorage.removeItem("keybind");
      window.location.reload();
    } else {
      toast({
        title: "Error",
        description:
          "There was an error deleting your profile. Please try again.",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleUserChange = (e) => {
    setUser(e.target.value);
  };

  return (
    <Box>
      <Box h="20vh" />
      <SlideFade in={true} delay={0.2} className="profile-wrapper">
        <HStack>
          <Avatar
            name={window.sessionStorage.getItem("user")}
            user={window.sessionStorage.getItem("user")}
            size="lg"
            // bgColor={"#30ACFB"}
          />
          {/* <h1 id="profile-h1"> {props.user} </h1> */}
          <Input
            placeholder={window.sessionStorage.getItem("user")}
            width="25%"
            onChange={(e) => handleUserChange(e)}
          ></Input>
          <h1 id="profile-score">Score: {points}</h1>
        </HStack>
        <Button
          mt={5}
          mb={10}
          colorScheme="red"
          size="xs"
          onClick={handleDelete}
        >
          Delete Profile
        </Button>
        <h2 id="profile-h2">Email</h2>
        <Input
          type="email"
          placeholder={email ? email : "Email..."}
          onChange={(e) => setEmail(e.target.value)}
        />
        <h2 id="profile-h2">Age</h2>
        <Box pt={2} pb={2} width="18%">
          <NumberInput
            value={age ? age : "18"}
            min={18}
            max={120}
            onChange={(val) => setAge(val)}
            bg="white"
            borderRadius={"md"}
            precision={0}
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </Box>
        <h2 id="profile-h2">Gender</h2>
        <Select
          // placeholder={gender ? gender : "Select..."}
          onChange={(e) => setGender(e.target.value)}
          bg="white"
          width="35%"
          defaultValue={gender ? gender : "Select..."}
        >
          <option hidden>{gender ? gender : "Select..."}</option>
          <option>Man</option>
          <option>Woman</option>
          <option>Prefer Not to Say</option>
        </Select>
        <h2 id="profile-h2">Dominant Hand</h2>
        <Select
          onChange={(e) => setHandedness(e.target.value)}
          bg="white"
          width="35%"
        >
          <option hidden disabled value="">
            {handedness ? handedness : "Select..."}
          </option>
          <option>Right</option>
          <option>Left</option>
          <option>Ambidexturous</option>
        </Select>
        <HStack my={20}>
          <Button colorScheme="teal" onClick={updateUserProfile}>
            Save Changes
          </Button>
          <Button colorScheme="teal" variant="outline" onClick={transition}>
            Done
          </Button>
        </HStack>
      </SlideFade>
    </Box>
  );
};

export default Profile;
