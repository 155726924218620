import React, { useState } from "react";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  useToast,
  Box,
  Select,
  Text,
  Input,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";

const SignUp = (props) => {
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [secondPassword, setSecondPassword] = useState();
  const [email, setEmail] = useState();
  const [age, setAge] = useState();
  const [gender, setGender] = useState();
  const [hand, setHand] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast({ position: "top" });
  const [seeCustom, setSeeCustom] = useState(false);
  const backend = process.env.REACT_APP_IP;
  const keybind = Math.random();

  const transition1 = () => {
    props.user(username);
    sessionStorage.setItem("user", username);
    sessionStorage.setItem("keybind", keybind);
    props.practice(true);
    props.setInTrial(true);
    props.seeNav(false);
    props.hideself(false);
    props.transition1(true);
  };

  const transition2 = () => {
    props.hideself(false);
    props.transition2(true);
  };

  const signUp = async () => {
    if (password !== secondPassword) {
      toast({
        title: "Passwords do not match",
        description: "Please check that your passwords match",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    } else if (username && hand) {
      // console.log("account creation: ", keybind);
      const body = JSON.stringify({
        username: username,
        password: secondPassword,
        email: email,
        age: age,
        gender: gender,
        hand: hand,
        keybind: keybind,
      });
      const response = await fetch(backend + "signup", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      });
      const result = await response.json();
      if (result === "error creating user") {
        toast({
          title: "Error",
          description:
            "There has been an error creating your account. Please try again",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else if (result === "user exists") {
        toast({
          title: "User already exists",
          description: "Please select another username",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      } else if (result === "success") {
        toast({
          title: "Account created",
          description: "Your account has been created",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
        transition1();
      }
    } else {
      toast({
        title: "Required fields",
        description: "Please enter all required fields",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const checkSame = (secondPass) => {
    if (secondPass === password) {
      setSecondPassword(secondPass);
    }
  };

  const updateForm = (e) => {
    if (e === "Custom") {
      setSeeCustom(true);
    } else {
      setSeeCustom(false);
      setGender(e);
    }
  };

  return (
    <Box className={`${props.shown ? "" : "active"} show`}>
      <Box className="module-container">
        <Box className="signup-box">
          <h1 id="title">Sign up to get started</h1>
          <Box
            display={"flex"}
            alignContent={"center"}
            justifyContent={"center"}
          >
            <Text size={"xs"} color="whiteAlpha.600" mr={1} my={3}>
              Already have an account?
            </Text>
            <button className="info-btn" type="button" onClick={transition2}>
              Sign in
            </button>
          </Box>

          <label>
            <span style={{ color: "red" }}>*</span> indicates required fields
          </label>
          <form
            onKeyUp={(e) => {
              if (e.key === "Enter") {
                signUp();
              }
            }}
          >
            {/* Labels and inputs for form data */}
            <label className="label">
              <span style={{ color: "red" }}>*</span>Username
            </label>
            <Input
              className="input"
              bg="white"
              type="text"
              autoComplete="username"
              onChange={(e) => setUsername(e.target.value)}
            />

            <label className="label">
              <span style={{ color: "red" }}>*</span>Password
            </label>
            <Input
              className="input"
              bg="white"
              type="password"
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
            />

            <label className="label">
              <span style={{ color: "red" }}>*</span>Confirm Password
            </label>
            <Input
              className="input"
              bg="white"
              type="password"
              autoComplete="new-password"
              onChange={(e) => checkSame(e.target.value)}
            />
            <Box>
              <label className="label">Email</label>
              <Text className="email-note" color="whiteAlpha.600">
                (Required to receive prizes)
              </Text>
            </Box>

            <Input
              className="input"
              bg="white"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
            />

            <label className="label">Age</label>
            <Box pt={2} pb={2}>
              <NumberInput
                min={18}
                max={120}
                onChange={(val) => setAge(val)}
                bg="white"
                borderRadius={"md"}
                precision={0}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Box>

            <label className="label">Gender</label>
            <Select
              placeholder="Select..."
              onChange={(e) => updateForm(e.target.value)}
              bg="white"
            >
              <option>Man</option>
              <option>Woman</option>
              <option>Prefer Not to Say</option>
            </Select>
            {seeCustom && (
              <>
                <div className="label">
                  <Input
                    className="input"
                    type="text"
                    bg="white"
                    mt={2}
                    onChange={(e) => setGender(e.target.value)}
                  />
                </div>
              </>
            )}
            <label className="label">
              <span style={{ color: "red" }}>*</span>Dominant Hand
            </label>
            <Select
              placeholder="Select..."
              onChange={(e) => setHand(e.target.value)}
              bg="white"
            >
              <option>Right</option>
              <option>Left</option>
              <option>Ambidextrous</option>
            </Select>
            <br></br>
            <button className="info-btn" type="button" onClick={onOpen}>
              Why do we collect this information?
            </button>
            <br></br>
            <br></br>
            <button
              onClick={() => {
                signUp();
              }}
              className="submit-btn"
              type="button"
            >
              Submit
            </button>

            <Modal isOpen={isOpen} onClose={onClose} isCentered>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>HOW WILL MY DATA BE USED?</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  We are using the data from this experiment to train a set of
                  AI models that are more aligned with biology than anything the
                  field has seen. Beyond creating newer and more computationally
                  efficient AI, this data can be used to robustly assess the
                  alignment between existing computer vision models and humans.
                  <br></br>
                  <br></br>
                  You are in control of your personal information. You always
                  have the right to delete your account, permanently removing
                  your email and personally identifiable data from all of our
                  databases.
                </ModalBody>

                <ModalFooter>
                  <Button colorScheme="blue" mr={3} onClick={onClose}>
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default SignUp;
